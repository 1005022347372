import '@otgourette/scss/app-front-page.scss';
import '@otgourette/js/bootstrap.bundle';
import Utils from '@otgourette/js/services/utils';
import Ux from '@otgourette/js/services/ux';
import Slider from '@otgourette/js/services/slider';
import Social from '@otgourette/js/services/social';
import Menu from "@otgourette/js/services/menu";
import ModalBooking from "@otgourette/js/services/modal-booking";

document.addEventListener( "DOMContentLoaded", function () {

    // Utils
    Utils.initializeLangSwitch();
    Utils.click();
    Utils.tooltip();
    Utils.fancybox();
    Utils.searchbox();
    Utils.smooth_scroll();
    Utils.lazy();
    Utils.sticky_navbar();
    Utils.modal();
    Utils.obfuscation();
    Utils.isotope();

    // Cursor
    new Ux;

    // Slider
    new Slider;

    // Social
    new Social();

    // Menu
    new Menu();

    // Booking
    new ModalBooking();

    //
})
