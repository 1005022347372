
import Swiper from 'swiper';
import {add_swiper_class} from '@otgourette/js/tools/swiper';
import Ux from '@otgourette/js/services/ux';
import { gsap } from "gsap";

export default class Slider {

	static #cursor_slider_prev = "[data-id='wpiris-cursor-slider-prev']";
	static #cursor_slider_next = "[data-id='wpiris-cursor-slider-next']";
	static #cursor_side;
	static #click_to_slide;

	constructor() {
		$(document).ready(function () {
			Slider.related_post();
			Slider.hero();
			if ( window.matchMedia( "(min-width: 1000px)" ).matches ) {
				Slider.init_cursor_slider_prev();
				Slider.init_cursor_slider_next();
				Slider.cursor_slider();

				window.addEventListener("mousemove", Slider.cursor_slider_prev_move);
				window.addEventListener("mousemove", Slider.cursor_slider_next_move);
				// window.addEventListener("mousemove", Slider.cursor_slider_next);
				//Slider.cursor_slider_related_post();
			}

		});
	}

	static related_post() {
		let slider = '[data-id="wpiris-related-post"]',
			slider_wrapper = '[data-id="wpiris-related-post-wrapper"]',
			slider_item = '[data-id="wpiris-related-post-item"]';

		if ($(slider).length > 0) {

			$(slider).each(function () {

				add_swiper_class($(slider), $(slider_wrapper), $(slider_item));
				const slides_per_view = undefined !== $(this).attr('data-slides-per-view') ? $(this).attr('data-slides-per-view') : 3
				const slides_per_view_small = undefined !== $(this).attr('data-slides-per-view-small') ? $(this).attr('data-slides-per-view-small') : 1
				const id              = $( this ).attr( 'id' );

				var related_post = new Swiper($(this), {
					//mousewheel: {
					//	forceToAxis: true,
					//},
					slidesPerView: slides_per_view_small,
					spaceBetween: 10,
					speed: 600,
					keyboard: true,
					freeMode: true,
					//mousewheelControl: false,
					//preventClicksPropagation: false,
					// allowTouchMove: false,
					watchSlidesVisibility: true,
					passiveListeners: false,
					navigation: {
						nextEl: '[data-arrow-ref="' + id + '"].swiper-gsap-arrow-next',
						prevEl: '[data-arrow-ref="' + id + '"].swiper-gsap-arrow-prev',
					},
					breakpoints: {
						650: {
							slidesPerView: 2,
						},
						1000: {
							slidesPerView: 3,
							spaceBetween: 15,
						},
						1381: {
							slidesPerView: slides_per_view,
						}
					}
				});

				related_post.on( 'touchStart', function ( e ) {
					if ( Slider.#click_to_slide === true ) {

						if ( Slider.#cursor_side == "left" ) {
							swiper_home.slidePrev();
						} else {
							swiper_home.slideNext();
						}
					}
				} );

			});
		}
	}

	static hero() {

		const slider = '[data-id="wpiris-hero-slider"]',
			slider_wrapper = '[data-id="wpiris-hero-slider-wrapper"]',
			slider_item = '[data-id="wpiris-hero-slider-item"]';

		var hero_slider_pagination = new Swiper('.hero-slider-pagination', {
			slidesPerView: 'auto',
			touchRatio: 0,
			allowTouchMove: false,
		});

		if ($(slider).length > 0) {

			add_swiper_class($(slider), $(slider_wrapper), $(slider_item));

			new Swiper($(slider), {
				touchRatio: 0,
				allowTouchMove: false,
				autoplay: {
					delay: 3000,
				},
				loop: false,
				speed: 1000,
				lazy: false,
				thumbs: {
					swiper: hero_slider_pagination,
				},
			});
		}
	}


	static init_cursor_slider_prev()
	{
		gsap.set( Slider.#cursor_slider_prev, {
			xPercent: -50,
			yPercent: -50,
			opacity: 0,
			scale: 0,
		} );

		// window.addEventListener( "mousemove", e => {
		// 	gsap.set(Ux.#cursor_slider_prev, {
		// 		x: e.clientX,
		// 		y: e.clientY
		// 	});
		// } );
	}

	static cursor_slider_prev_in() {

		gsap.to(Slider.#cursor_slider_prev, {
			duration: 0.3,
			opacity: 1,
			scale: 1,
		});
	}

	static cursor_slider_prev_out() {

		gsap.to(Slider.#cursor_slider_prev, {
			duration: 0.3,
			opacity: 0,
			scale: 0,
		});
	}

	static init_cursor_slider_next() {

		gsap.set(Slider.#cursor_slider_next, {
			xPercent: -50,
			yPercent: -50,
			opacity: 0,
			scale: 0,
		});

		// window.addEventListener( "mousemove", e => {
		// 	gsap.set(Ux.#cursor_slider_next, {
		// 		x: e.clientX,
		// 		y: e.clientY
		// 	});
		// } );
	}

	static cursor_slider_next_in() {

		gsap.to(Slider.#cursor_slider_next, {
			duration: 0.3,
			opacity: 1,
			scale: 1,
		});
	}

	static cursor_slider_next_out() {

		gsap.to(Slider.#cursor_slider_next, {
			duration: 0.3,
			opacity: 0,
			scale: 0,
		});
	}

	static cursor_slider_prev_move(e) {

		gsap.set(Slider.#cursor_slider_prev, {
			x: e.clientX,
			y: e.clientY
		});
	}

	static cursor_slider_next_move(e) {

		gsap.set(Slider.#cursor_slider_next, {
			x: e.clientX,
			y: e.clientY
		});
	}


	static cursor_slider()
	{
		if ( $( '.swiper-gsap' ).length > 0 ) {
			const slide_next  = ".swiper-gsap .swiper-gsap-arrow-next";
			const slide_prev  = ".swiper-gsap .swiper-gsap-arrow-prev";
			const slide_arrow = ".swiper-gsap .swiper-gsap-arrow";;

			$( document ).on( 'mouseenter', slide_prev, function () {
				Slider.cursor_slider_prev_in();
				Ux.cursor_out();
			} );

			$( document ).on( 'mouseenter', slide_next, function () {
				Slider.cursor_slider_next_in();
				Ux.cursor_out();
			} );

			$( document ).on( 'mouseleave', slide_arrow, function () {
				Slider.cursor_slider_prev_out();
				Slider.cursor_slider_next_out();
				Ux.cursor_in();
			} );
		}
	}

}
