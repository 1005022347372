import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import Modernizr from 'modernizr';
import LazyLoad from "vanilla-lazyload";
import Isotope from 'isotope-layout/dist/isotope.pkgd.min';

export default class Utils {

	static #shift = 0;

	constructor() {
		Utils.initializeLangSwitch();
		Utils.click();
		Utils.tooltip();
		Utils.fancybox();
		Utils.searchbox();
		Utils.smooth_scroll();
		Utils.lazy();
		Utils.block_cover();
		Utils.sticky_navbar();
		Utils.modal();
		Utils.obfuscation();
		Utils.isotope();
		Utils.switch();
	}

	static initializeLangSwitch() {
		function getCookie( cname )
		{
			var name = cname + "=";
			var ca   = document.cookie.split( ';' );
			for ( var i = 0; i < ca.length; i++ ) {
				var c = ca[ i ].trim();
				if ( c.indexOf( name ) == 0 ) {
					return c.substring( name.length, c.length );
				}
			}
			return "";
		}

		$('.langList a').each(function(){
			let content = $(this).html();
			content = content.replace(' ', '').toUpperCase();
			$(this).addClass('lang_'+content);
		});
		let langCurrent = $('.toolsItem--lang .langCurrent').html();
		langCurrent = langCurrent.replace(' ', '').toUpperCase();
		$('.langList a.lang_'+langCurrent).addClass('active');

		let current_lang = getCookie( 'googtrans' );
		let lang_item    = '[data-lang]';
		$( document ).on( 'click', lang_item, function () {
			let value = $( this ).html();
			$( '.langItem' ).removeClass( 'active' );
			$( this ).parent().addClass( 'active' );
			$( '.langCurrent' ).html( value );
		} );
	}

	static click() {

		const trigger = '[data-trigger="wpiris-click"]';

		if (!Modernizr.touchevents) {
			let ctrl = 0;
			$(window).on('keydown', function (e) {
				if (e.which == 17 || e.which == 91) {
					ctrl = true
				}
			})
			$(window).on('keyup', function () {
				ctrl = false
			})

			$(document).on('mouseup', trigger, function (e) {

				if (e.target.tagName.toLowerCase() !== 'a') {
					if (e.which == 1 || e.which == 2) {
						let blank = false
						let url = $(this).find("a").attr("href")
						if (e.which == 2) {
							blank = true
						} // Clic molette
						if ($(this).find("a").attr("target") == "_blank") {
							blank = true
						}
						if (blank || ctrl) {
							window.open(url, '_blank')
						} else {
							window.location = url
						}
						return false
					}
				}
				return false

			})
		} else {
			$(document).on('click', trigger, function (e) {

				let blank = false
				let url = $(this).find("a").attr("href")
				if ($(this).find("a").attr("target") == "_blank") {
					blank = true
				}
				if (blank) {
					window.open(url, '_blank')
				} else {
					window.location = url
				}
				return false

			})
		}
	}

	static tooltip() {
		if (!Modernizr.touchevents) {
			$('[data-toggle="tooltip"]').tooltip({trigger: "hover"})
		}
		$( '[data-toggle="popover"]' ).popover();
	}

	static fancybox() {

		$('.hentry a:has(>img):not([target="_blank"]):not("[nofancybox]")').attr('data-fancybox', 'fancybox')

		$('[data-fancybox="fancybox"]').fancybox({
			buttons: [
				"zoom",
				"slideShow",
				"close"
			],
			lang: "fr",
			i18n: {
				fr: {
					CLOSE: Theme.translate.fancyboxClose,
					NEXT: Theme.translate.fancyboxNext,
					PREV: Theme.translate.fancyboxPrev,
					ERROR: Theme.translate.fancyboxError,
					PLAY_START: Theme.translate.fancyboxStart,
					PLAY_STOP: Theme.translate.fancyboxStop,
					FULL_SCREEN: Theme.translate.fancyboxFullScreen,
					THUMBS: Theme.translate.fancyboxThumbs,
					DOWNLOAD: Theme.translate.fancyboxDownload,
					SHARE: Theme.translate.fancyboxShare,
					ZOOM: Theme.translate.fancyboxZoom
				},
			},
			caption: function (instance, item) {
				let caption = $(this).closest('figure').find('figcaption').html()

				if (!caption) {
					caption = $(this).find('img').attr('title')
				}

				if (!caption) {
					caption = $(this).find('img').attr('alt')
				}

				return caption
			}
		})
	}

	static searchbox() {
		const trigger = '[data-trigger="wpiris-search-layout"]',
			close = '[data-trigger="wpiris-search-layout-close"]',
			$element = $('[data-id="wpiris-search-layout"]');

		$(document).on('click', trigger, function (e) {
			e.preventDefault();
			$element.fadeIn().find('input').focus();
		})

		$(document).on('click', close, function (e) {
			e.preventDefault();
			$element.fadeOut();
		})
	}

	static smooth_scroll(shift = Utils.#shift) {
		const trigger = '[data-trigger="wpiris-scroll"]';

		$(document).on('click', trigger, function (e) {
			e.preventDefault();

			const target = $(this).attr("href") != '' ? $(this).attr("href") : $(this).attr("data-href"),
				duration = 1000,
				scroll_top = $(target).offset().top - (undefined !== e.currentTarget.dataset.shift ? e.currentTarget.dataset.shift : shift);

			$('html, body').animate({
				scrollTop: scroll_top
			}, duration);

		})
	}

	static lazy() {
		const lazyLoadInstance = new LazyLoad({
			elements_selector: '[data-src], [data-bg], [data-bg-hidpi]'
		});
		window.onbeforeprint = function () {
			lazyLoadInstance.loadAll();
		};
	}

	static block_cover() {
		$('.wp-block-cover:has(.wp-block-cover__inner-container a)').attr('data-trigger', 'wpiris-click')
	}

	static sticky_navbar() {

		// if (
		//     ($( '.page-template-sit' ).length == 0) &&
		//     ($( '.single-wpet' ).length == 0)
		// ) {

		let last_scroll = 0,
			direction = null,
			trigger_height = $('.navbar').outerHeight(),
			$element = $('.navbar, .toolbar'),
			$backtotop = $('.backtotop');

		// On scroll behavior
		$(window).scroll(function () {

			let scroll = $(window).scrollTop();

			// Determine wich direction
			if (scroll > last_scroll) {
				direction = 'bottom';
				last_scroll = scroll;
			} else {
				direction = 'top';
				last_scroll = scroll;
			}

			// Set sticky when scroll up
			if ((direction == "bottom") && (scroll > trigger_height)) {
				$element.removeClass('is-sticky');
				$element.addClass('is-hidden');
			} else {
				$element.addClass('is-sticky');
				$element.removeClass('is-hidden');
			}

			// Remove class if scroll top
			if (scroll == '0') {
				$element.removeClass('is-sticky');
			}

			// back to top
			if ( $( this ).scrollTop() > trigger_height ) {
				$backtotop.addClass( 'backtotop--show' );
			} else {
				$backtotop.removeClass( 'backtotop--show' );
			}
		});
		// }
	}


	static modal() {
		let trigger = '[data-trigger="wpiris-modal-toggle"]',
			element = '[data-id="wpiris-modal"]';

		if ($(trigger).length > 0) {
			$(document).on('click', trigger, function (e) {
				e.preventDefault();
				let target = $(this).attr('href');
				let classe = target.replace('#', '') + 'Open';
				$(target).toggleClass('open');
				$('body').toggleClass('noscroll');
				$('body').toggleClass(classe);
				setTimeout(function () {
					$(target).find('input:not(.pickadate)').filter(':first').focus();
				}, 100);
			});
		}
	}


	static obfuscation() {
		let element = "[data-obf]";
		if ($(element).length > 0) {
			if (!Modernizr.touchevents) {
				let ctrl = 0;
				$(window).on('keydown', function (e) {
					if (e.which == 17 || e.which == 91) {
						ctrl = true;
					}
				});
				$(window).on('keyup', function () {
					ctrl = false;
				});

				$(document).on('mouseup', element, function (e) {
					let url = atob($(this).attr('data-obf'));
					let target = $(this).attr('data-obf-target');
					if (e.which == 1 || e.which == 2) {
						let blank = false;
						if (e.which == 2) {
							blank = true;
						} // Clic molette
						if (target == "_blank") {
							blank = true;
						}
						if (blank || ctrl) {
							window.open(url, '_blank');
						} else {
							window.location = url;
						}
						return false;
					}

					return false;

				});
			} else {
				$(document).on('click', element, function (e) {
					let blank = false;
					let url = atob($(this).attr('data-obf'));
					let target = $(this).attr('data-obf-target');
					if (target == "_blank") {
						blank = true;
					}
					if (blank) {
						window.open(url, '_blank');
					} else {
						window.location = url;
					}
					return false;
				});
			}
		}
	}

	static isotope() {
		const grid = document.querySelector('[data-isotope-grid]'),
			item = '[data-isotope-item]',
			gutter_size_mobile = 10,
			$filters = $('[data-isotope-filters]');
		let gutter_size = 15;

		if (window.matchMedia("(max-width: 650px)").matches) {
			gutter_size = gutter_size_mobile;
		}

		//if ( $(grid).length() > 0 ) {

		var isotope = new Isotope(grid, {
			masonry: {
				itemSelector: item,
				gutter: gutter_size,
			}
		});
		//}

		if ($filters.length > 0) {
			// Filter items on button click
			$filters.on('click', 'a', function (e) {
				e.preventDefault();
				let filter_value = $(this).attr('data-filter');
				isotope.arrange({filter: filter_value});
			});

			// Change class on click
			$filters.each(function (i, button_group) {
				let $button_group = $(button_group);
				$button_group.on('click', 'a', function () {
					$button_group.find('.' + 'current').removeClass('current');
					$(this).addClass('current');
				});
			});
		}
	}

	static switch()
	{
		const trigger     = '[data-trigger="wpiris-switch"]',
			trigger_ode = '[data-switch-value]';
		let new_value     = 0;

		if ( trigger.length > 0 ) {
			$( document ).on( 'click', trigger, function ( e ) {
				e.preventDefault();
				let value = $( this ).attr( 'data-switch-active' );
				$( this ).toggleClass( 'switch--checked' );

				if ( 0 == value ) {
					$( this ).attr( 'data-switch-active', 1 );
					new_value = 1;

				} else {
					$( this ).attr( 'data-switch-active', 0 );
					new_value = 0;
				}

				$( document ).trigger( 'wpiris-switch-click', {
					value: new_value,
				} );
			} );
		}

		if ( trigger_ode.length > 0 && trigger.length > 0 ) {
			$( document ).on( 'click', trigger_ode, function ( e ) {
				e.preventDefault();
				let value = $( this ).attr( 'data-switch-value' );
				$( trigger ).attr( 'data-switch-active', value );

				if ( 0 == value ) {
					$( trigger ).removeClass( 'switch--checked' );
				} else {
					$( trigger ).addClass( 'switch--checked' );
				}

				$( document ).trigger( 'wpiris-switch-click', {
					value: value,
				} );
			} );
		}
	}


}
