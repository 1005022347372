export default class Menu {

	constructor()
	{
		Menu.burger();
	}

	static burger()
	{
		const trigger     = '[data-trigger="wpiris-menu-toggle"]',
			$element    = $( '[data-id="wpiris-menu"]' ),
			element_item = '[data-id="wpiris-menu-item"]',
			trigger_more = '[data-trigger="wpiris-menu-more"]',
			trigger_back = '[data-trigger="wpiris-menu-back"]';

		$( document ).on( 'click', trigger, function ( e ) {
			e.preventDefault();

			$element.toggleClass( 'open' );
			$( trigger ).toggleClass( 'open' );
			$( 'body' ).toggleClass( 'menu-open' );
			$( element_item ).removeClass( 'submenu-active' );
		} );

		$( document ).on( 'click', trigger_more, function ( e ) {
			$( element_item ).removeClass( 'submenu-active' );
			$( this ).parents( element_item ).addClass( 'submenu-active' );
		} );

		$( document ).on( 'click', trigger_back, function ( e ) {
			$( this ).parents( element_item ).removeClass( 'submenu-active' );
		} );
	}
}
