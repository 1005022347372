import "pickadate/lib/picker";
import "pickadate/lib/picker.date";
import "pickadate/lib/picker.time";
import "pickadate/lib/translations/fr_FR";

export default class ModalBooking {

	static #inputFrom;
	static #inputTo;

	constructor()
	{
		ModalBooking.init();
		ModalBooking.pickadate();
	}

	static init()
	{

		const $engine      = $( '[data-id="wpiris-engine-booking-item"]' ),
			engineSelect = ('[data-id="wpiris-engine-booking-select"]');

		if ( $( $engine.length > 0 ) ) {
			$engine.each( function () {
				let $engine_item = $( this );
				$engine_item.find( engineSelect ).on( 'change', function () {
					let url    = $( this ).find( 'option:selected' ).attr( 'rel' );
					let nb_adults = $( this ).find( 'option:selected' ).attr( 'data-adults' );
					let nb_childrens = $( this ).find( 'option:selected' ).attr( 'data-childrens' );

					if ( 1 == nb_adults ) {
						$engine_item.find( '[data-id="wpiris-engine-booking-adults"]' ).removeClass('hide');
					} else {
						$engine_item.find( '[data-id="wpiris-engine-booking-adults"]' ).addClass('hide');
					}

					if ( 1 == nb_childrens ) {
						$engine_item.find( '[data-id="wpiris-engine-booking-childrens"]' ).removeClass('hide');
					} else {
						$engine_item.find( '[data-id="wpiris-engine-booking-childrens"]' ).addClass('hide');
					}
					$engine_item.attr( 'action', url );
				} );

				$engine.find( 'button' ).on( 'click', function ( e ) {

					let pickerdeb = ModalBooking.#inputFrom.pickadate( 'picker' );
					let pickerend = ModalBooking.#inputTo.pickadate( 'picker' );

					let datedeb = pickerdeb.get( 'select' );
					let datefin = pickerend.get( 'select' );

					if ( datedeb !== null && datefin !== null && typeof datedeb.obj != 'undefined' && typeof datefin.obj != 'undefined' ) {

						let timeDiff = Math.abs( datefin.obj.getTime() - datedeb.obj.getTime() );
						let diffDays = Math.ceil( timeDiff / (
							1000 * 3600 * 24
						) );
						if ( diffDays == 0 ) {
							diffDays = 1;
						}
						$engine.find( '[name="marketplace_search[duration]"]' ).val( diffDays );
					}

					$engine.validationEngine( 'attach', {
						promptPosition: "topLeft",
						scroll: false
					} );

					if ( $engine.validationEngine( 'validate' ) == true ) {
						$engine.submit();
					}
					e.preventDefault();
					return false;
				} );
			} );
		}
	}

	static pickadate()
	{
		const $triggerFrom       = $( '[data-trigger="wpiris-pickadate-from"]' );
		const $triggerTo         = $( '[data-trigger="wpiris-pickadate-to"]' );
		ModalBooking.#inputFrom = $triggerFrom.pickadate( {
			format: 'd mmmm yyyy',
			formatSubmit: 'dd/mm/yyyy',
			min: true,
			onSet: function ( date ) {
				const date_from   = new Date( date.select );
				const date_to_min = new Date( date_from.setDate( date_from.getDate() + 1 ) );

				const picker_date_to = $triggerTo.pickadate( 'picker' );
				let date_to          = picker_date_to.get( 'select' );

				if ( date_to ) {
					date_to = date_to.obj;
				} else {
					date_to = new Date( $triggerTo.data( 'default-value' ) );
				}

				picker_date_to.set( 'min', date_to_min );

				if ( date_from >= date_to ) {
					picker_date_to.set( 'select', date_to_min );
				}
			}
		} );

		ModalBooking.#inputTo = $triggerTo.pickadate( {
			format: 'd mmmm yyyy',
			formatSubmit: 'dd/mm/yyyy',
			min: 1,
		} );

	}
}
