import domReady from '@wordpress/dom-ready';
import apiFetch from '@wordpress/api-fetch';
import Utils from '@otgourette/js/services/utils';

export default class Social {

	constructor()
	{
		Social.social_wall();
	}

	static social_wall() {

		if ( $( '[data-trigger="wpiris-social"]' ).length > 0 ) {

			domReady( () => {
				apiFetch( {
					path: Theme.social_wall_url,
					data: {
						limit: 5
					},
					method: 'POST'
				} ).then( ( result ) => {
					$( '[data-trigger="wpiris-social"]' ).html( result.render );
					Utils.lazy();
				} );
			} );
		}
	}
}