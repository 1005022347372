import {gsap} from "gsap";

export default class Ux {

	static #cursor = "[data-id='wpiris-cursor']";
	static #cursor_link = "[data-id='wpiris-cursor-link']";

	constructor() {
		Ux.init_cursor();
		Ux.init_cursor_link();

		window.addEventListener("mousemove", Ux.cursor_link_move);
		window.addEventListener("mousemove", Ux.cursor_move);

		const $triggers = document.querySelectorAll('a, [data-trigger="wpiris-click"]');
		$triggers.forEach(trigger => {
			trigger.addEventListener('mouseover', e => {
				Ux.cursor_link_in();
				Ux.cursor_out();
			});
			trigger.addEventListener('mouseout', e => {
				Ux.cursor_link_out();
				Ux.cursor_in();
			});
		});
	}

	static init_cursor() {

		gsap.set(Ux.#cursor, {
			xPercent: -50,
			yPercent: -50,
		});
	}

	static cursor_in() {

		gsap.to(Ux.#cursor, {
			duration: 0.3,
			scale: 1,
			opacity: 1
		});
	}

	static cursor_out() {

		gsap.to(Ux.#cursor, {
			duration: 0.3,
			scale: 0,
			opacity: 0
		});
	}

	static cursor_move(e) {

		gsap.set(Ux.#cursor, {
			x: e.clientX,
			y: e.clientY
		});
	}

	static init_cursor_link() {

		gsap.set(Ux.#cursor_link, {
			xPercent: -50,
			yPercent: -50,
			scale: 0,
			opacity: 0
		});
	}

	static cursor_link_in() {
		gsap.to(Ux.#cursor_link, {
			duration: 0.3,
			opacity: 1,
			scale: 1,
		})
	}

	static cursor_link_out() {
		gsap.to(Ux.#cursor_link, {
			duration: 0.3,
			scale: 0,
			opacity: 0
		})
	}

	static cursor_link_move(e) {

		gsap.set(Ux.#cursor_link, {
			x: e.clientX,
			y: e.clientY
		});
	}
}